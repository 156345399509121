import { send } from "../index";

export function login(data, opts = {}) {
  return send({
    url: "/admin/account/login.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updPassword(data, opts = {}) {
  return send({
    url: "/admin/adminUser/updPassword.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getDivision(data, opts = {}) {
	return send({
	  url: "/admin/administrativeDivision/list.do",
	  method: "POST",
	  data,
	  ...opts,
	});
}

export function getPower(data, opts = {}) {
  return send({
    url: "/admin/adminUser/getPower.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getMenuById(data, opts = {}) {
  return send({
    url: "/admin/adminMenu/listByParentId.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getMenuByParents(data, opts = {}) {
  return send({
    url: "/admin/adminMenu/listByParents.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function menuList(data, opts = {}) {
  return send({
    url: "/admin/adminMenu/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function addMenu(data, opts = {}) {
  return send({
    url: "/admin/adminMenu/add.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function menuDetail(data, opts = {}) {
  return send({
    url: "/admin/adminMenu/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function editMenu(data, opts = {}) {
  return send({
    url: "/admin/adminMenu/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteMenu(data, opts = {}) {
  return send({
    url: "/admin/adminMenu/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getLog(data, opts = {}) {
  return send({
    url: "/admin/adminLog/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function roleList(data, opts = {}) {
  return send({
    url: "/admin/adminRole/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function addRole(data, opts = {}) {
  return send({
    url: "/admin/adminRole/add.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteRole(data, opts = {}) {
  return send({
    url: "/admin/adminRole/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function roleDetail(data, opts = {}) {
  return send({
    url: "/admin/adminRole/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function editRole(data, opts = {}) {
  return send({
    url: "/admin/adminRole/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getRoleMenu(data, opts = {}) {
  return send({
    url: "/admin/adminRole/getMenu.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function setRoleMenu(data, opts = {}) {
    return send({
        url: '/admin/adminRole/editMenu.do',
        method: "POST",
        data,
        ...opts
    })
}


export function userList(data, opts = {}) {
  return send({
    url: "/admin/adminUser/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function userDetail(data, opts = {}) {
  return send({
    url: "/admin/adminUser/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function userUpdate(data, opts = {}) {
  return send({
    url: "/admin/adminUser/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function userAdd(data, opts = {}) {
  return send({
    url: "/admin/adminUser/add.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function userDel(data, opts = {}) {
  return send({
    url: "/admin/adminUser/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}
export function userChangeStatus(data, opts = {}) {
  return send({
    url: "/admin/adminUser/changeStatus.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getRoleUserList(data, opts = {}) {
  return send({
    url: "/admin/adminRole/listUser.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function unbindByUserId(data, opts = {}) {
  return send({
    url: "/admin/adminRole/unbindByUserId.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getProjectSettingDetail(data, opts = {}) {
  return send({
    url: "/admin/logoSetting/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateProjectSetting(data, opts = {}) {
  return send({
    url: "/admin/logoSetting/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateAdminUser(data, opts = {}) {
    return send({
        url: "/admin/adminUser/updateAdmin.do",
        method: "POST",
        data,
        ...opts,
    })
}

export function updateRenew(data, opts = {}) {
    return send({
        url: "/admin/renewSetting/update.do",
        method: "POST",
        data,
        ...opts,
    })
}

export function getRenew(data, opts = {}) {
    return send({
        url: "/admin/adminUser/getRenew.do",
        method: "POST",
        data,
        ...opts,
    })
}

export function getRenewDetail(data, opts = {}) {
    return send({
        url: "/admin/renewSetting/detail.do",
        method: "POST",
        data,
        ...opts,
    })
}

export function getCinemaSetting(data, opts = {}) {
	//  获取影院配置接口
  return send({
    url: "/admin/cinemaSetting/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function setCinemaSetting(data, opts = {}) {
	//  设置影院配置接口
  return send({
    url: "/admin/cinemaSetting/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateCinemaSetting(data, opts = {}) {
	//  设置影院配置接口
  return send({
    url: "/admin/cinema/updateCinemaSetting.do",
    method: "POST",
    data,
    ...opts,
  });
}
