import {
	createRouter,
	createWebHistory
} from "vue-router";
import layout from "../layout/index";
import {
	initRouter
} from "@/utils/routerUtil.js";

// 不需要登录拦截的路由配置
export const loginIgnore = {
	names: ["404", "403"], //根据路由名称匹配
	paths: ["/login"], //根据路由fullPath匹配
	/**
	 * 判断路由是否包含在该配置中
	 * @param route vue-router 的 route 对象
	 * @returns {boolean}
	 */
	includes(route) {
		return this.names.includes(route.name) || this.paths.includes(route.path);
	},
};

// 不需要展示到左侧菜单栏的路由
export const constantRoutes = [{
	path: "/login",
	name: "login",
	component: () => import("@/views/login/index"),
},
{
	path: "/403",
	name: "403",
	meta: {
		title: '权限不足'
	},
	component: () => import("@/views/exception/403"),
},
];

export let asyncRoutes = [{
	path: "/",
	name: "home",
	component: layout,
	redirect: "/index",
	meta: {
		title: "首页",
		icon: "HomeOutlined",
	},
	children: [{
		path: "index",
		name: "index",
		meta: {
			title: "仪表盘",
			icon: "AreaChartOutlined"
		},
		component: () => import("@/views/index/index"),
	}],
},
{
	path: "/system",
	name: 'system',
	meta: {
		title: '系统',
		icon: "ToolOutlined",
		permission: "system"
	},
	component: layout,
	children: [{
		path: "systemRoot",
		name: "systemRoot",
		meta: {
			title: "系统管理",
			icon: "ToolOutlined",
			permission: "system_manage"
		},
		component: () => import("@/views/system/role/role"),
		children: [{
			path: "role",
			name: "role",
			meta: {
				title: "角色管理",
				icon: "UsergroupDeleteOutlined",
				permission: "system_manage_role"
			},
			component: () => import("@/views/system/role/role"),
			children: [{
				path: "view",
				name: "roleView",
				meta: {
					title: "角色列表",
					permission: "system_manage_role"
				},
				hidden: true,
				component: () => import("@/views/system/role/role"),
			},
				// {
				// 	path: "add",
				// 	name: "roleAdd",
				// 	meta: {
				// 		title: "新增编辑",
				// 	},
				// 	hidden: true,
				// 	component: () => import("@/views/system/role/add"),
				// },
				// {
				// 	path: "edit",
				// 	name: "roleEdit",
				// 	meta: {
				// 		title: "角色编辑",
				// 	},
				// 	hidden: true,
				// 	component: () => import("@/views/system/role/edit"),
				// },
				// {
				// 	path: "power",
				// 	name: "rolePower",
				// 	meta: {
				// 		title: "角色权限",
				// 	},
				// 	hidden: true,
				// 	component: () => import("@/views/system/role/power"),
				// },
			],
		},
		{
			path: "user",
			name: "user",
			meta: {
				title: "用户管理",
				icon: "UserOutlined",
				permission: "system_manage_user"
			},
			component: () => import("@/views/system/user/user"),
			children: [{
				path: "view",
				name: "userView",
				meta: {
					title: "用户列表",
					icon: "UserOutlined",
					permission: "system_manage_user"
				},
				hidden: true,
				component: () => import("@/views/system/user/user"),
			},
			{
				path: "userAdd",
				name: "user_add",
				meta: {
					title: "新增用户",
					permission: "system_manage_user_add"
				},
				hidden: true,
				component: () => import("@/views/system/user/userAdd"),
			},
			{
				path: "userEdit",
				name: "userEdit",
				meta: {
					title: "用户编辑",
					permission: "system_manage_user_edit"
				},
				hidden: true,
				component: () => import("@/views/system/user/userEdit"),
			},
			],
		},

		{
			path: "systemLog",
			name: "systemLog",
			meta: {
				title: "日志管理",
				icon: "ScheduleOutlined",
				permission: "system_manage_log"
			},
			component: () => import("@/views/system/systemLog/systemLog"),
		},
		{
			path: "menu",
			name: "menu",
			meta: {
				title: "菜单管理",
				icon: "ScheduleOutlined",
				permission: "system_manage_menu"
			},
			component: () => import("@/views/system/menu/menu"),
			children: [{
				path: "view",
				name: "menuView",
				meta: {
					title: "菜单列表",
					permission: "system_manage_menu"
				},
				hidden: true,
				component: () => import("@/views/system/menu/menu"),
			},
			{
				path: "add",
				name: "menuAdd",
				meta: {
					title: "新增菜单",
					permission: "system_manage_menu_add"
				},
				hidden: true,
				component: () => import("@/views/system/menu/add"),
			},
			{
				path: "edit",
				name: "menuEdit",
				meta: {
					title: "菜单编辑",
					permission: "system_manage_menu_edit"
				},
				hidden: true,
				component: () => import("@/views/system/menu/edit"),
			},
			],
		},
		{
			path: "cinemaSetting",
			name: "cinemaSetting",
			meta: {
				title: '影院配置',
				icon: 'BankOutlined',
				permission: "system_manage_cinemaSetting"
			},
			component: ()=>import('@/views/system/cinema/index.vue')
		},
		{
			path: "project",
			name: "project",
			meta: {
				title: '项目管理',
				icon: 'RobotOutlined',
				permission: "system_manage_project"
			},
			component: () => import('@/views/system/project/index')
		}, {
			path: "renewal",
			name: "renewal",
			meta: {
				title: "续费管理",
				icon: "AccountBookOutlined",
				permission: "system_manage_renewal"
			},
			component: () => import("@/views/system/renewal/index")
		}
		],
	}, {
		path: "task",
		name: "task",
		meta: {
			title: '任务中心',
			icon: "SnippetsOutlined",
			permission: "system_task"
		},
		component: () => import("@/views/exportTask/list.vue"),
		children: [{
			path: "list",
			name: "taskList",
			meta: {
				title: '导出任务列表',
				permission: "system_task_list"
			},
			component: () => import("@/views/exportTask/list.vue"),
		},
			// {
			// 	path: 'importList',
			// 	name: "taskImportList",
			// 	meta: {
			// 		title: '导入任务列表',
			// 		permission: "system_task_import"
			// 	},
			// 	component: () => import("@/views/exportTask/importList.vue"),
			// },
		]
	}]
},
{
	path: "/cinema",
	name: "cinema",
	meta: {
		title: '影院',
		icon: "BankOutlined",
		permission: "ciname"
	},
	component: layout,
	children: [{
		path: "basis",
		name: "basis",
		meta: {
			title: '影院基础',
			icon: "ShopOutlined",
			permission: "ciname_basis"
		},
		component: () => import("@/views/cinema/basis/organize/organize.vue"),
		children: [{
			path: 'organize',
			name: 'organize',
			meta: {
				title: '影院组织',
				permission: "ciname_basis_organize"
			},
			component: () => import("@/views/cinema/basis/organize/organize.vue")
		}, {
			path: "info",
			name: "cinemaInfo",
			meta: {
				title: '影院信息',
				permission: "ciname_basis_info"
			},
			component: () => import("@/views/cinema/basis/info/info.vue"),
		}, {
			path: 'film',
			name: "film",
			meta: {
				title: '影片信息',
				permission: "ciname_basis_film"
			},
			component: () => import("@/views/cinema/basis/film/list.vue")
		}, {
			path:'filmTicket',
			name: 'filmTicket',
			meta: {
				title: '影片购票模式',
				permission: 'ciname_basis_filmTicket'
			},
			component: () => import("@/views/cinema/basis/filmTicket/index.vue")
		}]
	}, {
		path: 'policy',
		name: 'policy',
		meta: {
			title: '政策设置',
			icon: "HourglassOutlined",
			permission: "ciname_policy"
		},
		component: () => import("@/views/cinema/policy/pricing/pricing.vue"),
		children: [{
			path: "pricing",
			name: "pricing",
			meta: {
				title: '场次基础定价',
				permission: "ciname_policy_pricing"
			},
			component: () => import("@/views/cinema/policy/pricing/pricing.vue"),
		}, {
			path: "refundTicket",
			name: "refundTicket",
			meta: {
				title: '会员退票政策',
				permission: "ciname_policy_refund"
			},
			component: () => import("@/views/cinema/policy/refundTicket/refundTicket.vue")
		}, {
			path: "holiday",
			name: "holiday",
			meta: {
				title: '编辑公共假期',
				permission: "ciname_policy_holiday"
			},
			component: () => import("@/views/cinema/policy/holiday/holiday.vue")
		},
		{
			path: "defaultMemberCard",
			name: "defaultMemberCard",
			meta: {
				title: '默认会员卡号',
				permission: "ciname_policy_defaultMemberCard"
			},
			component: () => import("@/views/cinema/policy/defaultMemberCard/defaultMemberCard.vue")
		}
	]
	}]
},
{
	path: "/coupon",
	name: "coupon",
	meta: {
		title: '卡券',
		icon: "WalletOutlined",
		permission: "coupon"
	},
	component: layout,
	children: [{
		path: "timesCard",
		name: "couponTimesCard",
		meta: {
			title: '次卡管理',
			icon: "CopyOutlined",
			permission: "coupon_timesCard"
		},
		component: () => import("@/views/coupon/timesCard/policy/policy.vue"),
		children: [{
			path: "policy",
			name: "timesCardPolicy",
			meta: {
				title: '次卡等级',
				permission: "coupon_timesCard_policy"
			},
			component: () => import("@/views/coupon/timesCard/policy/policy.vue"),
		}, {
			path: "info",
			name: "timesCardInfo",
			meta: {
				title: '次卡信息',
				permission: "coupon_timesCard_info"
			},
			component: () => import("@/views/coupon/timesCard/info/info.vue"),
		}, {
			path: "infoDetails",
			name: "timesCardInfoDetails",
			meta: {
				title: '次卡信息明细表',
				permission: "coupon_timesCard_info_details"
			},
			component: () => import("@/views/coupon/timesCard/info/infoDetails.vue"),
		}, {
			path: "detailInfo",
			name: "timesCardDetailInfo",
			meta: {
				title: '次卡明细信息',
				permission: "coupon_timesCard_detail_info"
			},
			component: () => import("@/views/coupon/timesCard/info/info.vue"),
		}, {
			path: "sale",
			name: "timesCardSale",
			meta: {
				title: '次卡消费',
				permission: "coupon_timesCard_sale"
			},
			component: () => import("@/views/coupon/timesCard/sale/sale.vue"),
		},
			// {
			// 	path: "delay",
			// 	name: "timesCardDelay",
			// 	meta: {
			// 		title: '次卡延期'
			// 	},
			// 	component: () => import("@/views/coupon/timesCard/delay/delay.vue"),
			// },
		]
	}, {
		path: "yearCard",
		name: "couponYearCard",
		meta: {
			title: '年卡管理',
			icon: "LayoutOutlined",
			permission: "coupon_yearCard"
		},
		component: () => import("@/views/coupon/yearCard/info/info.vue"),
		children: [{
			path: "policy",
			name: 'yearCardPolicy',
			meta: {
				title: '年卡等级',
				permission: "coupon_yearCard_policy"
			},
			component: () => import("@/views/coupon/yearCard/policy/policy.vue")
		}, {
			path: "info",
			name: 'yearCardInfo',
			meta: {
				title: '年卡信息',
				permission: "coupon_yearCard_info"
			},
			component: () => import("@/views/coupon/yearCard/info/info.vue")
		}, {
			path: "detailInfo",
			name: "yearCardDetailInfo",
			meta: {
				title: '年卡明细信息',
				permission: "coupon_yearCard_detail_info"
			},
			component: () => import("@/views/coupon/yearCard/info/info.vue"),
		},
		// {
		// 	path: "transfer",
		// 	name: 'yearCardTransfer',
		// 	meta: {
		// 		title: '转赠记录'
		// 	},
		// 	component: () => import("@/views/coupon/yearCard/transfer/transfer.vue")
		// }, {
		// 	path: "delay",
		// 	name: 'yearCardDelay',
		// 	meta: {
		// 		title: '年卡延期'
		// 	},
		// 	component: () => import("@/views/coupon/yearCard/delay/delay.vue")
		// },
		{
			path: "sale",
			name: 'yearCardSale',
			meta: {
				title: '年卡消费',
				permission: "coupon_yearCard_sale"
			},
			component: () => import("@/views/coupon/yearCard/sale/sale.vue")
		}
		]
	}, {
		path: "stored",
		name: "stored",
		meta: {
			title: '线上会员卡管理',
			icon: "DollarOutlined",
			permission: "coupon_stored"
		},
		component: () => import("@/views/coupon/stored/policy/policy.vue"),
		children: [{
			path: "policy",
			name: "storedPolicy",
			meta: {
				title: '线上会员卡等级',
				permission: "coupon_stored_policy"
			},
			component: () => import("@/views/coupon/stored/policy/policy.vue"),
		}, {
			path: 'list',
			name: "storedList",
			meta: {
				title: '线上会员卡列表',
				permission: 'coupon_stored_list'
			},
			component: () => import("@/views/coupon/stored/list/list.vue")
		}, {
			path: "info",
			name: "storedInfo",
			meta: {
				title: '线上会员卡信息',
				permission: "coupon_stored_info"
			},
			component: () => import("@/views/coupon/stored/info/info.vue"),
		}, {
			path: "activity",
			name: "storedActivity",
			meta: {
				title: "线上会员卡充值活动",
				permission: "coupon_stored_activity"
			},
			component: () => import("@/views/coupon/stored/activity/list.vue")
		}, {
			path: "bind",
			name: "storedBind",
			meta: {
				title: '发放线上会员卡',
				permission: "coupon_stored_bind"
			},
			component: () => import("@/views/coupon/stored/bind/index.vue")
		}, {
			path: "mini",
			name: "storedMini",
			meta: {
				title: '推广线上会员等级',
				permission: "coupon_stored_buy"
			},
			component: () => import("@/views/coupon/stored/bind/list.vue")
		},
			{
				path: "balanceChangesReport",
				name: "storedBalanceChanges",
				meta: {
					title: '线上会员卡余额变动报表',
					permission: "coupon_stored_balanceChangesReport"
				},
				component: () => import("@/views/coupon/stored/balanceChangesReport/list.vue")
			},
		]
	},
	// 影城会员卡
	{
		path: "memberCard",
		name: "memberCard",
		meta: {
			title: '影城会员卡管理',
			icon: "DollarOutlined",
			permission: "coupon_memberCard"
		},
		component: () => import("@/views/coupon/memberCard/info/info.vue"),
		children: [
			{
				path: "info",
				name: "memberCardInfo",
				meta: {
					title: '影城会员卡信息',
					permission: "coupon_memberCard_info"
				},
				component: () => import("@/views/coupon/memberCard/info/info.vue"),
			},
			{
			path: "activity",
			name: "memberCardActivity",
			meta: {
				title: "影城会员卡充值活动",
				permission: "coupon_memberCard_activity"
			},
			component: () => import("@/views/coupon/memberCard/activity/list.vue")
		},{
			path: "bind",
			name: "memberCardBind",
			meta: {
				title: '绑定影城会员卡',
				permission: "coupon_memberCard_bind"
			},
			component: () => import("@/views/coupon/memberCard/bind/index.vue")
		},
			{
				path: "balanceChangesReport",
				name: "memberCardBalanceChanges",
				meta: {
					title: '影城会员卡余额变动报表',
					permission: "coupon_memberCard_balanceChangesReport"
				},
				component: () => import("@/views/coupon/memberCard/balanceChangesReport/list.vue")
			},
		]
	},

	// {
	// 	path: "exchange",
	// 	name: "exchange",
	// 	meta: {
	// 		title: '兑换券管理',
	// 		icon: "FundOutlined",
	// 		// permission: "coupon_exchange"
	// 	},
	// 	component: () => import("@/views/coupon/exchange/info/info.vue"),
	// 	children: [{
	// 			path: "info",
	// 			name: "exchangeInfo",
	// 			meta: {
	// 				title: '兑换券信息',
	// 				// permission: "coupon_exchange_info"
	// 			},
	// 			component: () => import("@/views/coupon/exchange/info/info.vue"),
	// 		},
	// 		{
	// 			path: "export",
	// 			name: "exchangeExport",
	// 			meta: {
	// 				title: '兑换券入券库',
	// 				// permission: "coupon_exchange_export"
	// 			},
	// 			component: () => import("@/views/coupon/exchange/export/export.vue"),
	// 		}, {
	// 			path: "list",
	// 			name: "exchangeList",
	// 			meta: {
	// 				title: '兑换券列表',
	// 				// permission: "coupon_exchange_list"
	// 			},
	// 			component: () => import("@/views/coupon/exchange/list/list.vue"),
	// 		},
	// 	]
	// },
	{
		path: "preferential",
		name: "preferential",
		meta: {
			title: '兑换券管理',
			icon: "InboxOutlined",
			permission: "coupon_preferential"
		},
		component: () => import("@/views/coupon/preferential/list/list.vue"),
		children: [{
			path: "rules",
			name: "rules",
			meta: {
				title: '使用政策列表',
				permission: 'coupon_preferential_rules'
			},
			component: () => import("@/views/coupon/preferential/rules/list.vue")
		}, {
			path: "record",
			name: "preferentialRecord",
			meta: {
				title: '兑换券批次表',
				permission: "coupon_preferential_record"
			},
			component: () => import("@/views/coupon/preferential/record/record.vue")
		}, {
			path: "recordInfo",
			name: "preferentialRecordInfo",
			meta: {
				title: '兑换券批次明细表',
				permission: "coupon_preferential_recordInfo"
			},
			component: () => import("@/views/coupon/preferential/record/record.vue")
		},
		{
			path: "list",
			name: "preferentialList",
			meta: {
				title: '兑换券列表',
				permission: "coupon_preferential_list"
			},
			component: () => import("@/views/coupon/preferential/list/list.vue"),
		}, {
			path: "info",
			name: "preferentialInfo",
			meta: {
				title: '兑换券信息',
				permission: "coupon_preferential_info"
			},
			component: () => import("@/views/coupon/preferential/info/info.vue")
		}, {
			path: "detail",
			name: "preferentialDetail",
			meta: {
				title: '兑换券信息明细表',
				permission: "coupon_preferential_detail"
			},
			component: () => import("@/views/coupon/preferential/info/detail.vue")
		}, {
			path: "sale",
			name: "preferentialSale",
			meta: {
				title: '兑换券消费',
				permission: "coupon_preferential_sale"
			},
			component: () => import("@/views/coupon/preferential/sale/sale.vue")
		}
		]
	},
	{
    				path: "tiktok",
    				name: "tiktok",
    				meta: {
    					title: '抖音券管理',
    					icon: "ShakeOutlined",
    					permission: "coupon_tiktok"
    				},
    				component: () => import("@/views/coupon/tiktok/policy/policy.vue"),
    				children: [{
    					path: "policy",
    					name: "tiktokPolicy",
    					meta: {
    						title: '抖音券等级',
    						permission: "coupon_tiktok_policy"
    					},
    					component: () => import("@/views/coupon/tiktok/policy/policy.vue"),
    				}, {
    					path: "sale",
    					name: "tiktokSale",
    					meta: {
    						title: '抖音券消费',
    						permission: "coupon_tiktok_sale"
    					},
    					component: () => import("@/views/coupon/tiktok/sale/sale.vue"),
    				}]
    			},
	{
		path: "snack",
		name: "snack",
		meta: {
			title: '卖品券管理',
			icon: "ShoppingCartOutlined",
			permission: "coupon_snack"
		},
		component: () => import("@/views/coupon/snack/info/info.vue"),
		children: [{
			path: "record",
			name: "snackRecord",
			meta: {
				title: '卖品券批次表',
				permission: "coupon_snack_record"
			},
			component: () => import("@/views/coupon/snack/record/record.vue")
		}, {
			path: "recordInfo",
			name: "snackRecordInfo",
			meta: {
				title: '卖品券批次明细表',
				permission: "coupon_snack_recordInfo"
			},
			component: () => import("@/views/coupon/snack/record/record.vue")
		}, {
			path: "list",
			name: "couponSnackList",
			meta: {
				title: '卖品券列表',
				permission: "coupon_snack_list"
			},
			component: () => import("@/views/coupon/snack/list/list.vue")
		}, {
			path: "info",
			name: "snackInfo",
			meta: {
				title: '卖品券信息',
				permission: "coupon_snack_info"
			},
			component: () => import("@/views/coupon/snack/info/info.vue")
		}, {
			path: "sale",
			name: "snackSale",
			meta: {
				title: '卖品券消费',
				permission: "coupon_snack_sale"
			},
			component: () => import("@/views/coupon/snack/sale/sale.vue")
		}]
	},
	{
		path: "couponTask",
		name: "couponTask",
		meta: {
			title: '任务中心',
			icon: "SnippetsOutlined",
			permission: "coupon_task"
		},
		component: () => import("@/views/exportTask/importList.vue"),
		children: [{
			path: 'importList',
			name: "taskImportList",
			meta: {
				title: '卡券任务列表',
				permission: "coupon_task_import"
			},
			component: () => import("@/views/exportTask/importList.vue"),
		}]
	}
	]
},
{
	path: "/operation",
	name: "operation",
	meta: {
		title: '运营',
		icon: "SyncOutlined",
		permission: "operation"
	},
	component: layout,
	children: [{
		path: "advert",
		name: "advert",
		meta: {
			title: '广告营销',
			icon: 'PictureOutlined',
			permission: "operation_advert"
		},
		component: () => import("@/views/operation/advert/adWindow/adWindow.vue"),
		children: [{
			path: "adWindow",
			name: "adWindow",
			meta: {
				title: '广告弹窗管理',
				permission: "operation_advert_adWindow"
			},
			component: () => import("@/views/operation/advert/adWindow/adWindow.vue"),
		},
		{
			path: "banner",
			name: "banner",
			meta: {
				title: '轮播图管理',
				permission: "operation_advert_banner"
			},
			component: () => import("@/views/operation/advert/banner/banner.vue"),
		}
		]
	}, {
		path: "agreement",
		name: "agreement",
		meta: {
			title: '协议管理',
			icon: 'CopyOutlined',
			permission: "operation_agreement"
		},
		component: () => import("@/views/operation/agreement/list.vue"),
		children: [{
			path: "list",
			name: "agreementList",
			meta: {
				title: '协议列表',
				permission: "operation_agreement_list"
			},
			component: () => import("@/views/operation/agreement/list.vue"),
		}]
	}, {
		path: "extension",
		name: "operationExtension",
		meta: {
			title: '推广券活动',
			icon: 'FireOutlined',
			permission: "operation_extension"
		},
		component: () => import("@/views/operation/extension/list.vue"),
		children: [{
			path: "list",
			name: 'operationExtensionList',
			meta: {
				title: '活动列表',
				permission: "operation_extension_list"
			},
			component: () => import("@/views/operation/extension/list.vue"),
		}]
	}, {
		path: "bill",
		name: "operationBill",
		meta: {
			title: '海报背景图管理',
			icon: "FileImageOutlined",
			permission: "operation_bill"
		},
		component: () => import("@/views/operation/bill/common/list.vue"),
		children: [{
			path: "common",
			name: "operationBillCommon",
			meta: {
				title: '通用海报背景图',
				permission: "operation_bill_common"
			},
			component: () => import("@/views/operation/bill/common/list.vue")
		}, {
			path: "ticket",
			name: "operationBillTicket",
			meta: {
				title: '购票页海报背景图',
				permission: "operation_bill_ticket"
			},
			component: () => import("@/views/operation/bill/ticket/list.vue")
		}]
	}, {
		path: "activity",
		name: "operationActivity",
		meta: {
			title: '活动管理',
			icon: "AppstoreAddOutlined",
			permission: "operation_activity"
		},
		component: ()=>import("@/views/operation/activity/list.vue"),
		children: [{
			path: "list",
			name: "operationActivityList",
			meta: {
				title: '活动列表',
				permission: "operation_activity_list"
			},
			component: ()=>import("@/views/operation/activity/list.vue"),
		}]
	}
		// {
		// 	path: "refund",
		// 	name: "refund",
		// 	meta: {
		// 		title: '退款审批',
		// 		icon: 'PayCircleOutlined'
		// 	},
		// 	component: () => import("@/views/operation/refund/ticket/ticket.vue"),
		// 	children: [{
		// 		path: "ticket",
		// 		name: "ticket",
		// 		meta: {
		// 			title: '退影票审批',
		// 		},
		// 		component: () => import("@/views/operation/refund/ticket/ticket.vue"),
		// 	}, {
		// 		path: "goods",
		// 		name: "goods",
		// 		meta: {
		// 			title: '退商品审批',
		// 		},
		// 		component: () => import("@/views/operation/refund/goods/goods.vue"),
		// 	}, {
		// 		path: "saleGoods",
		// 		name: "saleGoods",
		// 		meta: {
		// 			title: '退卖品审批',
		// 		},
		// 		component: () => import("@/views/operation/refund/saleGoods/saleGoods.vue"),
		// 	}]
		// },
	]
},
{
	path: "/report",
	name: "report",
	meta: {
		title: '报表',
		icon: "SnippetsOutlined",
		permission: "report"
	},
	component: layout,
	children: [{
		path: "revenue",
		name: "revenueReport",
		meta: {
			title: '门店运营综合报表',
			icon: "FundProjectionScreenOutlined",
			permission: "report_revenue"
		},
		component: () => import("@/views/report/revenue/list.vue"),
		children: [{
			path: "list",
			name: "revenueListReport",
			meta: {
				title: "营收汇总报表",
				permission: "report_revenue_list"
			},
			component: () => import("@/views/report/revenue/list.vue"),
		}, {
			path: "goodsSale",
			name: "goodsSaleReport",
			meta: {
				title: '商城销售明细表',
				permission: "report_revenue_goodsSale"
			},
			component: () => import("@/views/report/revenue/goods/report.vue")
		}, {
			path: "paySale",
			name: "paySaleReport",
			meta: {
				title: '微信支付购票明细表',
				permission: "report_revenue_paySale"
			},
			component: () => import("@/views/report/revenue/pay/report.vue")
		}, {
			path: "sendersSale",
			name: "sendersSaleReport",
			meta: {
				title: '补差明细表',
				permission: "report_revenue_sendersSale"
			},
			component: () => import("@/views/report/revenue/senders/report.vue")
		}, {
           					path: "tiktok",
           					name: "tiktokReport",
           					meta: {
           						title: '抖音明细表',
           						permission: "report_revenue_tiktok"
           					},
           					component: () => import("@/views/report/revenue/tiktok/index.vue")
         },{
			path: "code",
			name: "codeReport",
			meta: {
				title: '前台扫码付款表',
				permission: "report_revenue_code"
			},
			component: () => import("@/views/report/revenue/code/report.vue")
		}, {
			path: "seatSale",
			name: "seatSaleReport",
			meta: {
				title: '人数统计表',
				permission: "report_revenue_seatSale"
			},
			component: () => import("@/views/report/revenue/seat/report.vue")
		}, {
			path: "seatSaleRealtime",
			name: "seatSaleRealtimeReport",
			meta: {
				title: '实时人数统计表',
				permission: "report_revenue_seatSaleRealtime"
			},
			component: () => import("@/views/report/revenue/seatRealtime/report.vue")
		}, {
			path: "filmDay",
			name: "filmDayReport",
			meta: {
				title: '电影日人数统计表',
				permission: "report_revenue_filmDay"
			},
			component: () => import("@/views/report/revenue/filmDay/report.vue")
		}]
	}, {
		path: "snackReport",
		name: "snackReport",
		meta: {
			title: '卖品报表',
			icon: "PieChartOutlined",
			permission: "snack_report"
		},
		component: () => import("@/views/report/snack/sale/list.vue"),
		children: [{
			path: "sale",
			name: "snackReportSale",
			meta: {
				title: '卖品销售明细表',
				permission: "snack_report_sale"
			},
			component: () => import("@/views/report/snack/sale/list.vue"),
		}, {
			path: "saleRealtime",
			name: "snackReportSaleRealtime",
			meta: {
				title: '实时卖品销售明细表',
				permission: "snack_report_sale_realtime"
			},
			component: () => import("@/views/report/snack/saleRealtime/list.vue"),
		}, {
			path: "verification",
			name: "snackReportVerification",
			meta: {
				title: '卖品核销明细表',
				permission: "snack_report_verification"
			},
			component: () => import("@/views/report/snack/verification/list.vue")
		}]
	},
	{
		path: "yearCard",
		name: "yearCardReport",
		meta: {
			title: '年卡报表',
			icon: "ProfileOutlined",
			permission: "report_yearCard"
		},
		component: () => import("@/views/report/yearCard/publish/publish.vue"),
		children: [{
			path: "publish",
			name: "yearPublish",
			meta: {
				title: '年卡发行报表',
				permission: "report_yearCard_publish"
			},
			component: () => import("@/views/report/yearCard/publish/publish.vue"),
		}, {
			path: "consume",
			name: "yearConsume",
			meta: {
				title: '年卡消费报表',
				permission: "report_yearCard_consume"
			},
			component: () => import("@/views/report/yearCard/consume/consume.vue"),
		}]
	},
	{
		path: "timesCard",
		name: "timesCardReport",
		meta: {
			title: '次卡报表',
			icon: "ReadOutlined",
			permission: "report_timesCard"
		},
		component: () => import("@/views/report/timesCard/publish/publish.vue"),
		children: [{
			path: "publish",
			name: "timesPublish",
			meta: {
				title: '次卡发行报表',
				permission: "report_timesCard_publish"
			},
			component: () => import("@/views/report/timesCard/publish/publish.vue"),
		}, {
			path: "consume",
			name: "timesConsume",
			meta: {
				title: '次卡消费报表',
				permission: "report_timesCard_consume"
			},
			component: () => import("@/views/report/timesCard/consume/consume.vue"),
		}]
	}, {
		path: "stored",
		name: "storedReport",
		meta: {
			title: "线上会员卡报表",
			icon: "DollarOutlined",
			permission: "report_stored"
		},
		component: () => import("@/views/report/stored/sale/index.vue"),
		children: [{
			path: "sale",
			name: "storedReportSale",
			meta: {
				title: "线上会员卡消费报表",
				permission: "report_stored_sale"
			},
			component: () => import("@/views/report/stored/sale/index.vue"),
		}, {
			path: "recharge",
			name: "storedReportRecharge",
			meta: {
				title: "线上会员卡充值报表",
				permission: "report_stored_excharge"
			},
			component: () => import("@/views/report/stored/recharge/index.vue")
		},{
			path: "sellingGoods",
			name: "storedReportSellingGoods",
			meta: {
				title: "线上会员卡卖品消费报表",
				permission: "report_stored_sellingGoods"
			},
			component: () => import("@/views/report/stored/sellingGoods/list.vue")
		}]
	},{
		path: "memberCard",
		name: "memberCardReport",
		meta: {
			title: "影城会员卡报表",
			icon: "DollarOutlined",
			permission: "report_memberCard"
		},
		component: () => import("@/views/report/memberCard/sale/index.vue"),
		children: [{
			path: "sale",
			name: "memberCardReportSale",
			meta: {
				title: "影城会员卡消费报表",
				permission: "report_memberCard_sale"
			},
			component: () => import("@/views/report/memberCard/sale/index.vue"),
		}, {
			path: "recharge",
			name: "memberCardReportRecharge",
			meta: {
				title: "影城会员卡充值报表",
				permission: "report_memberCard_excharge"
			},
			component: () => import("@/views/report/memberCard/recharge/index.vue")
		},{
			path: "sellingGoods",
			name: "memberCardReportSellingGoods",
			meta: {
				title: "影城会员卡卖品消费报表",
				permission: "report_memberCard_sellingGoods"
			},
			component: () => import("@/views/report/memberCard/sellingGoods/list.vue")
		}]
	}, {
		path: "coupon",
		name: "exchangeCoupon",
		meta: {
			title: '兑换券报表',
			icon: "AuditOutlined",
			permission: "report_exchange"
		},
		component: () => import("@/views/report/coupon/publish/publish.vue"),
		children: [{
			path: "publish",
			name: "couponPublish",
			meta: {
				title: '兑换券发行报表',
				permission: "report_exchange_publish"
			},
			component: () => import("@/views/report/coupon/publish/publish.vue"),
		}, {
			path: "consume",
			name: "couponConsume",
			meta: {
				title: '兑换券消费报表',
				permission: "report_exchange_consume"
			},
			component: () => import("@/views/report/coupon/consume/consume.vue"),
		},
//		{
//			path: "oldPublish",
//			name: "couponOldPublish",
//			meta: {
//				title: '(旧)兑换券发行报表',
//				permission: "report_exchange_oldPublish"
//			},
//			component: () => import("@/views/report/coupon/publish/oldPublish.vue"),
//		}, {
//			path: "oldConsume",
//			name: "couponOldConsume",
//			meta: {
//				title: '(旧)兑换券消费报表',
//				permission: "report_exchange_oldConsume"
//			},
//			component: () => import("@/views/report/coupon/consume/oldConsume.vue"),
//		}
        ]
	},
	{
		path: "ticket",
		name: "ticketReport",
		meta: {
			title: '影票报表',
			icon: "VideoCameraOutlined",
			permission: "report_ticket"
		},
		component: () => import("@/views/report/ticket/sale.vue"),
		children: [{
			path: "sale",
			name: "ticketSaleReport",
			meta: {
				title: "影票报表",
				permission: "report_ticket_sale"
			},
			component: () => import("@/views/report/ticket/sale.vue"),
		}, {
			path: "afterSale",
			name: 'ticketAfterSaleReport',
			meta: {
				permission: "report_ticket_afterSale",
				title: '售后审核'
			},
			component: () => import("@/views/report/ticket/afterSale.vue")
		}, {
			path: "saleDetailRealtime",
			name: "ticketSaleDetailRealtimeReport",
			meta: {
				title: "实时影票明细报表",
				permission: "report_ticket_sale_detail_realtime"
			},
			component: () => import("@/views/report/ticketRealtime/sale.vue"),
		}, {
			path: "saleDetail",
			name: "ticketSaleDetailReport",
			meta: {
				title: "影票明细报表",
				permission: "report_ticket_sale_detail"
			},
			component: () => import("@/views/report/ticket/saleDetail.vue"),
		},
		{
			path: "seat",
			name: "ticketSeatReport",
			meta: {
				title: '影票座位明细表',
				permission: 'report_ticket_seat'
			},
			component: () => import("@/views/report/ticket/seat.vue")
		},
		{
			path: "incomefilm",
			name: "ticketIncomeFilm",
			meta: {
				title: "实时影片数据统计",
				permission: "report_ticket_income_film"
			},
			component: () => import("@/views/report/ticketIncome/film.vue"),
		},
		{
			path: "incomeCinema",
			name: "ticketIncomeCinema",
			meta: {
				title: "实时门店数据统计",
				permission: "report_ticket_income_cinema"
			},
			component: () => import("@/views/report/ticketIncome/cinema.vue"),
		},


				]
			}, {
				path: "wechat",
				name: "report_wechat",
				meta: {
					title: "现金购票实时统计",
					icon: "PoundOutlined",
					permission: "report_wechat"
				},
				component: () => import("@/views/report/wechat/index.vue"),
				children: [{
					path: "list",
					name: "report_wechat_list",
					meta: {
						title: '现金购票实时统计表',
						permission: "report_wechat_list"
					},
					component: () => import("@/views/report/wechat/index.vue"),
				}]
			}, {
				path: "restore",
				name: "reportRestore",
				meta: {
					title: '恢复订单至售票系统',
					icon: "ReloadOutlined",
					permission: "report_restore"
				},
				component: () => import("@/views/report/restore/list.vue"),
				children: [{
					path: "list",
					name: "reportRestoreList",
					meta: {
						title: "恢复订单至售票系统",
						permission: "report_restore_list"
					},
					component: () => import("@/views/report/restore/list.vue"),
				}]
			},
		{
			path: "normal",
			name: "normalReport",
			meta: {
				title: '影院常用报表',
				icon: "ReloadOutlined",
				permission: "normal_report"
			},
			component: () => import("@/views/report/normal/ticket.vue"),
			children: [{
				path: "ticket",
				name: "normalReportTicket",
				meta: {
					title: "影票报表",
					permission: "normal_report_ticket"
				},
				component: () => import("@/views/report/normal/ticket.vue"),
			},{
				path: "shop",
				name: "normalReportShop",
				meta: {
					title: "商城销售明细表",
					permission: "normal_report_shop"
				},
				component: () => import("@/views/report/normal/shop.vue"),
			},{
				path: "snack",
				name: "normalReportSnack",
				meta: {
					title: "卖品销售明细表",
					permission: "normal_report_snack"
				},
				component: () => import("@/views/report/normal/snack.vue"),
			}]
		}
		]
	},
	{
		path: "/member",
		name: "member",
		meta: {
			title: '会员',
			icon: "UserOutlined",
			permission: "member"
		},
		component: layout,
		children: [{
				path: "memberManage",
				name: "memberManage",
				meta: {
					title: '会员管理',
					icon: "ContactsOutlined",
					permission: "member_manage"
				},
				component: () => import("@/views/member/memberManage/info/info.vue"),
				children: [{
					path: "info",
					name: "info",
					meta: {
						title: '会员信息',
						permission: "member_manage_info"
					},
					component: () => import("@/views/member/memberManage/info/info.vue"),
				}]
			},
			{
				path: "label",
				name: "memberLabel",
				meta: {
					title: '标签管理',
					icon: 'TagsOutlined',
					permission: "member_label"
				},
				component: () => import("@/views/member/label/list.vue"),
				children: [{
					path: "list",
					name: "memberLabelList",
					meta: {
						title: '标签列表',
						permission: 'member_label_list'
					},
					component: () => import("@/views/member/label/list.vue")
				}]
			},
			{
				path: "distributor",
				name: "distributor",
				meta: {
					title: '分销商管理',
					icon: 'UsergroupAddOutlined',
					permission: "member_distributor"
				},
				component: () => import("@/views/member/distributor/list/list.vue"),
				children: [{
					path: "list",
					name: "distributorList",
					meta: {
						title: '分销商列表',
						permission: "member_distributor_list"
					},
					component: () => import("@/views/member/distributor/list/list.vue")
				}, {
					path: "level",
					name: "distributorLevel",
					meta: {
						title: '分销等级',
						permission: "member_distributor_level"
					},
					component: () => import("@/views/member/distributor/level/level.vue")
				}, {
					path: "setting",
					name: "distributorSetting",
					meta: {
						title: '分销设置',
						permission: "member_distributor_setting"
					},
					component: () => import("@/views/member/distributor/setting/setting.vue")
				}, {
					path: "ticket",
					name: "distributorTicket",
					meta: {
						title: '现金购票分销配置',
						permission: "member_distributor_ticket"
					},
					component: () => import("@/views/member/distributor/ticket/list.vue")
				}, {
					path: 'report',
					name: 'distributorReport',
					meta: {
						title: '分销报表',
						permission: 'member_distributor_report'
					},
					component: () => import("@/views/member/distributor/report/list.vue")
				}]
			}
		]
	},
	{
		path: "/mall",
		name: "mall",
		meta: {
			title: '商城',
			icon: "ShoppingOutlined",
			permission: "mall"
		},
		component: layout,
		children: [{
			path: "shop",
			name: "shop",
			meta: {
				title: '商城管理',
				icon: "ShopOutlined",
				permission: "mall_shop"
			},
			component: () => import("@/views/mall/shop/shop.vue"),
			children: [{
				path: "info",
				name: "shopInfo",
				meta: {
					title: '商城信息',
					permission: "mall_shop_info"
				},
				component: () => import("@/views/mall/shop/shop.vue"),
			}]
		}, {
			path: "goods",
			name: "mallGoods",
			meta: {
				title: '商品管理',
				icon: "ShoppingCartOutlined",
				permission: "mall_goods"
			},
			component: () => import("@/views/mall/goods/publish/publish.vue"),
			children: [{
				path: "cinemaGoods",
				name: "cinemaGoods",
				meta: {
					title: '影院商品管理',
					permission: "mall_goods_cinema"
				},
				component: () => import("@/views/mall/goods/cinemaGoods/cinemaGoods.vue")
			}, {
				path: "goodsList",
				name: "goodsList",
				meta: {
					title: '商品库',
					permission: "mall_goods_template"
				},
				component: () => import("@/views/mall/goods/goodsList/goodsList.vue")
			}, {
				path: "classify",
				name: "goodsClassify",
				meta: {
					title: '商品分类',
					permission: "mall_setting_classify"
				},
				component: () => import("@/views/mall/goodsSetting/classify/classify.vue"),
			}, {
				path: "brand",
				name: "goodsBrand",
				meta: {
					title: '商品品牌',
					permission: "mall_setting_brand"
				},
				component: () => import("@/views/mall/goodsSetting/brand/brand.vue"),
			}, {
				path: "freight",
				name: "freight",
				meta: {
					title: '商品运费设置',
					permission: "mall_setting_freight"
				},
				component: () => import("@/views/mall/goodsSetting/freight/freight.vue")
			}]
		}, {
			path: "mallOrder",
			name: "mallOrder",
			meta: {
				title: "订单管理",
				icon: "SolutionOutlined",
				permission: "mall_order"
			},
			component: () => import("@/views/mall/order/mallOrder/order.vue"),
			children: [{
				path: "list",
				name: "mallOrderList",
				meta: {
					title: '订单列表',
					permission: "mall_order_list"
				},
				component: () => import("@/views/mall/order/mallOrder/order.vue"),
			}, {
				path: "setting",
				name: "mallOrdersetting",
				meta: {
					title: '订单支付设置',
					permission: "mall_order_setting"
				},
				component: () => import("@/views/mall/order/setting/setting.vue"),
			}]
		}]
	},
	{
		path: "/snackManage",
		name: "snackManage",
		meta: {
			title: '卖品',
			icon: "WalletOutlined",
			permission: "snack"
		},
		component: layout,
		children: [{
				path: "setting",
				name: "snackManageSetting",
				meta: {
					title: '卖品基础设置',
					icon: "SettingOutlined",
					permission: "snack_setting"
				},
				component: () => import("@/views/snackManage/snackSetting/classify/classify.vue"),
				children: [{
						path: "classify",
						name: "snackClassify",
						meta: {
							title: "卖品分类",
							permission: "snack_setting_classify"
						},
						component: () => import("@/views/snackManage/snackSetting/classify/classify.vue")
					},
					// {
					// 	path: "warehouse",
					// 	name: "snackWarehouse",
					// 	meta: {
					// 		title: '仓库管理'
					// 	},
					// 	component: () => import("@/views/snackManage/snackSetting/warehouse/list.vue")
					// }, {
					//     path: "suppliers",
					//     name: "snackSuppliers",
					//     meta: {
					//         title: '供货商管理'
					//     },
					//     component: () => import("@/views/snackManage/snackSetting/suppliers/list.vue")
					// }, {
					//     path: "unit",
					//     name: "snackUnit",
					//     meta: {
					//         title: '常用单位管理'
					//     },
					//     component: () => import("@/views/snackManage/snackSetting/unit/list.vue")
					// },
				]
			}, {
				path: "snack",
				name: "snackManageGoods",
				meta: {
					title: '卖品管理',
					icon: "ShoppingCartOutlined",
					permission: 'snack_manage'
				},
				component: () => import("@/views/snackManage/goods/cinemaSnack/cinemaSnack.vue"),
				children: [{
						path: "snackList",
						name: "snackList",
						meta: {
							title: '卖品库',
							permission: 'snack_manage_template'
						},
						component: () => import("@/views/snackManage/goods/snackList/snackList.vue")
					}, {
						path: "cinemaSnack",
						name: "cinemaSnack",
						meta: {
							title: '影院卖品管理',
							permission: 'snack_manage_cinema'
						},
						component: () => import("@/views/snackManage/goods/cinemaSnack/cinemaSnack.vue"),
					},
					//				{
					//					path: "stock",
					//					name: "snackStock",
					//					meta: {
					//						title: '卖品进货'
					//					},
					//					component: () => import("@/views/snackManage/warehouse/stock/list.vue")
					//				}, {
					//					path: "transfer",
					//					name: "snackTransfer",
					//					meta: {
					//						title: '卖品转移'
					//					},
					//					component: () => import("@/views/snackManage/warehouse/transfer/list.vue")
					//				}
				]
			},
			{
				path: "order",
				name: "snackManageOrder",
				meta: {
					title: '订单管理',
					icon: "SolutionOutlined",
					permission: "snack_order"
				},
				component: () => import("@/views/snackManage/order/snackOrder/snackOrder.vue"),
				children: [{
					path: "list",
					name: "snackManageOrderList",
					meta: {
						title: '卖品订单',
						permission: "snack_order_list"
					},
					component: () => import("@/views/snackManage/order/snackOrder/snackOrder.vue"),
				}]
			}
		]
	},
	{
		path: "/ledger",
		name: "ledger",
		meta: {
			title: '分账',
			icon: "DiffOutlined",
			permission: 'ledger'
		},
		component: layout,
		children: [{
			path: "manage",
			name: "ledgerManage",
			meta: {
				title: '分账管理',
				icon: "ContainerOutlined",
				permission: 'ledger_manage'
			},
			component: () => import("@/views/ledger/manage/list/list.vue"),
			children: [{
				path: "list",
				name: "ledgerList",
				meta: {
					title: '分账管理列表',
					permission: 'ledger_manage_list'
				},
				component: () => import("@/views/ledger/manage/list/list.vue"),
			}]
		}, {
			path: "user",
			name: "ledgerUser",
			meta: {
				title: '分账用户配置',
				icon: "UserAddOutlined",
				permission: 'ledger_user'
			},
			component: () => import("@/views/ledger/user/configure/list.vue"),
			children: [{
				path: "configure",
				name: "userLedgerConfigure",
				meta: {
					title: '分账用户配置列表',
					permission: 'ledger_user_list'
				},
				component: () => import("@/views/ledger/user/configure/list.vue"),
			},{
				path: "rule",
				name: "userLedgerRuleConfigure",
				meta: {
					title: '分账组规则配置表',
					permission: 'ledger_user_rule_list'
				},
				component: () => import("@/views/ledger/user/rule/rule.vue"),
			}
			]
		}, {
			path: "setting",
			name: "ledgerSetting",
			meta: {
				title: '分账商品配置',
				icon: "PartitionOutlined",
				permission: 'ledger_setting'
			},
			component: () => import("@/views/ledger/goodsSetting/goods/goods.vue"),
			children: [{
				path: "goods",
				name: "ledgerSettingGoods",
				meta: {
					title: '商品分账配置',
					permission: 'ledger_setting_goods'
				},
				component: () => import("@/views/ledger/goodsSetting/goods/goods.vue"),
			}, {
				path: "snack",
				name: "ledgerSettingSnack",
				meta: {
					title: '卖品分账配置',
					permission: "ledger_setting_snack"
				},
				component: () => import("@/views/ledger/goodsSetting/snack/list.vue")
			}, {
				path: "ticket",
				name: "ledgerSettingTicket",
				meta: {
					title: '现金购票分账配置',
					permission: 'ledger_setting_ticket'
				},
				component: () => import("@/views/ledger/goodsSetting/ticket/ticket.vue"),
			}, {
				path: "allGoods",
				name: "ledgerAllGoods",
				meta: {
					title: '影院商品分账配置',
					permission: 'ledger_setting_allGoods'
				},
				component: () => import("@/views/ledger/goodsSetting/allGoods/list.vue"),
			}, {
				path: "allSnack",
				name: "ledgerAllSnack",
				meta: {
					title: '影院卖品分账配置',
					permission: 'ledger_setting_allSnack'
				},
				component: () => import("@/views/ledger/goodsSetting/allSnack/list.vue"),
			}, {
				path: "recharge",
				name: "ledgerRecharge",
				meta: {
					title: '充值分账配置',
					permission: 'ledger_setting_recharge'
				},
				component: () => import("@/views/ledger/goodsSetting/recharge/list.vue"),
			},
			 // {
			 // 	path: "exchange",
			 // 	name: "ledgerSettingExchange",
			 // 	meta: {
			 // 		title: '(旧)兑换券补差分账配置',
			 // 		permission: 'ledger_setting_exchange'
			 // 	},
			 // 	component: () => import("@/views/ledger/goodsSetting/exchange/exchange.vue"),
			 // },
			{
				path: "timeCard",
				name: "ledgerSettingTimeCard",
				meta: {
					title: '次卡补差分账配置',
					permission: 'ledger_setting_timecard'
				},
				component: () => import("@/views/ledger/goodsSetting/timeCard/timeCard.vue"),
			}, {
				path: "preferential",
				name: "ledgerSettingPreferential",
				meta: {
					title: '兑换券补差分账配置',
					permission: 'ledger_setting_preferential'
				},
				component: () => import("@/views/ledger/goodsSetting/preferential/list.vue"),
			},
//			{
//			    path: "stored",
//			    name: "ledgerSettingStored",
//			    meta: {
//			        title: "线上会员卡充值分账配置",
//					permission: "ledger_setting_stored"
//			    },
//			    component: () => import("@/views/ledger/goodsSetting/stored/list.vue")
//			},{
//              	path: "memberCard",
//              	name: "ledgerSettingMemberCard",
//              	meta: {
//              		title: "影城会员卡充值分账配置",
//              		permission: "ledger_setting_memberCard"
//              	},
//              	component: () => import("@/views/ledger/goodsSetting/memberCard/list.vue")
//              }
              ]
		}]
	},
	{
		path: "/sales",
		name: "sales",
		meta: {
			title: '核销中心',
			icon: "SafetyCertificateOutlined",
			permission: 'verify_sale'
		},
		component: layout,
		children: [{
			path: "manage",
			name: "verifySaleManage",
			meta: {
				title: '核销管理',
				icon: "TransactionOutlined",
				permission: 'verify_sale_manage'
			},
			component: () => import("@/views/sales/manage/goods/goods.vue"),
			children: [{
				path: "goods",
				name: "verifySaleStore",
				meta: {
					title: '商城自提商品核销',
					permission: 'verify_sale_manage_goods'
				},
				component: () => import("@/views/sales/manage/goods/goods.vue"),
			}, {
				path: "saleGoods",
				name: "verifySaleGoods",
				meta: {
					title: '自提卖品核销',
					permission: 'verify_saleManage_snack'
				},
				component: () => import("@/views/sales/manage/saleGoods/saleGoods.vue"),
			}, {
				path: 'snack',
				name: "verifySnack",
				meta: {
					title: '第三方卖品核销',
					permission: 'verify_manage_snack'
				},
				component: () => import("@/views/sales/manage/snack/list.vue")
			}]
		}]
	},
	{
		path: "/buyTickets",
		name: "buyTickets",
		meta: {
			title: '购票',
			icon: "SafetyCertificateOutlined",
			permission: 'buyTickets'
		},
		component: layout,
		children: [{
			path: "tickets",
			name: "tickets",
			meta: {
				title: '购票',
				icon: "TransactionOutlined",
				permission: 'buyTickets_tickets'
			},
			component: () => import("@/views/buyTickets/index.vue"),
		}]
	},
	{
		path: "/:pathMatch(.*)*",
		name: "404",
		hidden: true,
		meta: {
			title: '路由不存在'
		},
		component: () => import("@/views/exception/404"),
	},
];

const routes = [
	// ...asyncRoutes,
	...constantRoutes,
];

export const router = createRouter({
	history: createWebHistory(process.env.NODE_ENV === 'production' ? '/dashboard/' : '/'),
	routes,
	scrollBehavior() {
		// 始终滚动到顶部
		// see https://next.router.vuejs.org/zh/guide/advanced/scroll-behavior.html
		return {
			top: 0
		};
	},
});

// 初始化路由
initRouter();

export default router;
